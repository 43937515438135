<template>
  <div class="payrolls">
    <div class="header-payrolls">
      <h1>
        <span class="pr-4"><i class="fal fa-calendar-alt"></i></span>{{ $t("payroll.title") }}
      </h1>
      <div class="btn-save-change payroll-btn" @click="onCreate">
        <span><i class="fal fa-plus"></i></span>{{ $t("payroll.create") }}
      </div>
    </div>
    <v-card class="card-payrolls pt-5">
      <v-row>
        <v-col md="6">
          <DatePicker class="date-time" style="width: 100%" type="date" placeholder="Select date" v-model="startDate"
            valueType="format" slot="activator" :append-to-body="true" @change="handleEventFilterDate($event, 'start')">
          </DatePicker>
        </v-col>
        <v-col md="6">
          <DatePicker class="date-time" style="width: 100%" type="date" placeholder="Select date" v-model="endDate"
            valueType="format" slot="activator" :append-to-body="true" @change="handleEventFilterDate($event, 'end')">
          </DatePicker>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="card-payrolls-content">
      <table class="table table-view">
        <thead>
          <tr>
            <th class="text-left">{{ $t("payroll.no") }}</th>
            <th class="text-left">{{ $t("payroll.name") }}</th>
            <th class="text-left">{{ $t("payroll.createdBy") }}</th>
            <th class="text-left">{{ $t("payroll.status") }}</th>
            <th class="text-left"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in listPayrolls" :key="index">
            <td>{{ index + 1 }}</td>
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ item.created_by.name }}
            </td>
            <td>
              <div class="status" v-if="item.status === 'pending'">
                <span class="status pending">Pending</span>
              </div>
              <div class="status" v-if="item.status === 'processing'">
                <span class="status processing">Processing</span>
              </div>
              <div class="status" v-if="item.status === 'approved'">
                <span class="status approved">Approved</span>
              </div>
            </td>
            <td></td>
            <td class="text-end">
              <v-menu transition="slide-y-transition" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="addEmployeeInPayrollsItem(item.id, item.qty_day_work, item.qty_hour_work)" outlined
                    v-bind="attrs" v-on="on">
                    <i class="far fa-calculator" style="font-size: 1.5rem"></i>
                  </v-btn>
                </template>
              </v-menu>
            </td>
            <td width="40">
              <v-btn icon color="primary" v-if="item.status === 'pending'" @click="editPayroll(item.id)">
                <v-icon>mdi-square-edit-outline</v-icon>
              </v-btn>
              <v-btn icon disabled color="pink" v-if="item.status !== 'pending'">
                <v-icon>mdi-square-edit-outline</v-icon>
              </v-btn>
            </td>
            <td width="40">
              <v-btn icon color="pink" @click="deletePayroll(item.id)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </table>
      <Loading v-if="isLoading" />
      <ModalAdd :title="$t('payroll.form_title.create')">
        <template v-slot="{ close }">
          <CreatePayrollsItem @close="close" @success="fetchPayrolls" />
        </template>
      </ModalAdd>
      <ModalEdit :title="$t('payroll.form_title.update')">
        <template v-slot="{ close }">
          <EditPayrollItem :payroll_id="payroll_id" @close="close" @success="fetchPayrolls" />
        </template>
      </ModalEdit>
      <ModalDelete>
        <template v-slot="{ close }">
          <DeletePayroll :payroll_id="payroll_id" @close="close" @success="successDelete" />
        </template>
      </ModalDelete>
    </v-card>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import CreatePayrollsItem from "@/views/MainCompany/PayrollsItem/modal/CreatePayrollsItem";
import DeletePayroll from "@/views/MainCompany/PayrollsItem/modal/DeletePayroll";
import EditPayrollItem from "@/views/MainCompany/PayrollsItem/modal/EditPayrollItem";
import Loading from "@/components/Loading";

export default {
  components: {
    CreatePayrollsItem,
    DatePicker,
    Loading,
    DeletePayroll,
    EditPayrollItem,
  },
  data() {
    return {
      listPayrolls: [],
      startDate: "",
      endDate: "",
      isLoading: false,
      payroll_id: "",
    };
  },
  methods: {
    onCreate() {
      this.$store.commit("modalAdd_State", true);
    },
    addEmployeeInPayrollsItem(payroll_id, qty_day_work, qty_hour_work) {
      if (!qty_day_work) {
        var date = new Date(),
          y = date.getFullYear(),
          m = date.getMonth();
        var lastDay = new Date(y, m + 1, 0).getDate();
        qty_day_work = lastDay;
      }
      if (!qty_hour_work) {
        qty_hour_work = 8;
      }
      setTimeout(() => {
        this.$router
          .push({
            name: "payrolls.index",
            params: {
              payroll_id: payroll_id,
              qty_day_work: qty_day_work,
              qty_hour_work: qty_hour_work,
            },
          })
          .catch(() => { });
      });
    },

    handleEventFilterDate(event, type) {
      if (type === "start") {
        this.startDate = event;
      } else {
        this.endDate = event;
      }
      const item = {
        startDate: this.startDate,
        endDate: this.endDate,
      };
      this.fetchPayrolls(item);
    },
    fetchPayrolls(item) {
      this.isLoading = true;
      this.$axios
        .post(`company/list/payrolls`, item)
        .then((res) => {
          if (res.status === 200) {
            this.listPayrolls = res.data.data;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    deletePayroll(payroll_id) {
      this.payroll_id = payroll_id;
      this.$store.commit("modalDelete_State", true);
    },
    editPayroll(payroll_id) {
      this.payroll_id = payroll_id;
      this.$store.commit("modalEdit_State", true);
    },
    successDelete() {
      this.fetchPayrolls({
        startDate: this.startDate,
        endDate: this.endDate,
      });
    },
  },
  created() {
    this.fetchPayrolls({
      startDate: this.startDate,
      endDate: this.endDate,
    });
  },
};
</script>

<style scoped lang="scss">
.payrolls {
  width: 100%;
  min-height: 100vh;
  background: #eeeeee;

  .header-payrolls {
    width: 100%;
    height: auto;
    display: flex;
    position: relative;

    h1 {
      font-size: 1.4rem;
      font-family: $font-family;
      padding: 20px 10px 5px 15px;
    }

    .payroll-btn {
      width: 180px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 15px;
      top: 15px;
      border-radius: 4px;
      cursor: pointer;
      transition: ease-out 0.2s;

      span {
        margin-right: 8px;
      }
    }
  }

  .card-payrolls {
    margin: 10px 15px;
    padding: 0 10px;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    h1 {
      font-family: $font-family;
      font-size: 1.2rem;
      margin: 15px;
    }

    .row {
      margin: 0;
      padding: 0;
      border: 0;
    }
  }

  .card-payrolls-content {
    margin: 20px 15px;
    padding: 20px;
    min-height: 500px;
  }
}

.status {
  padding: 2px 12px;
  font-size: 14px;
  color: #ffffff;
  border-radius: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .pending {
    background: rgba(255, 77, 77, 0.8);
  }

  .approved {
    background: rgba(23, 192, 235, 1);
  }

  .processing {
    background: rgba(58, 227, 116, 1);
  }
}
</style>
