<template>
  <div class="add-payrolls">
    <div class="payrolls-title" style="padding: 30xp;">
      <!-- <h1><span><i class="far fa-calendar-alt"></i></span>{{ $t('payroll.create_payroll.title') }}</h1> -->
      <h1>{{ $t('payroll.create_payroll.title') }}</h1>
    </div>
    <v-row style="padding: 30px 30px 5px 30px;">
      <v-col cols="12" sm="12" md="12">
        <v-text-field outlined dense :label="$t('payroll.create_payroll.input_title')"
          v-model="newTitle"></v-text-field>
      </v-col>
    </v-row>
    <v-row style="padding: 10px 30px">
      <v-col cols="6" sm="6" md="6">
        <DatePicker class="date-time" style="width: 100%;" type="date" valueType="format" slot="activator"
          :placeholder="$t('payroll.create_payroll.start_date')" :append-to-body="true" v-model="start_date">
        </DatePicker>
      </v-col>
      <v-col cols="6" sm="6" md="6">
        <DatePicker class="date-time" style="width: 100%;" type="date" valueType="format" slot="activator"
          :placeholder="$t('payroll.create_payroll.end_date')" :append-to-body="true" v-model="end_date"></DatePicker>
      </v-col>
    </v-row>

    <v-row style="padding: 30px">
      <v-col cols="6" sm="6" md="6">
        <v-text-field outlined dense :label="$t('payroll.create_payroll.working_day_per_month')"
          v-model="qty_day_work"></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field outlined dense :label="$t('payroll.create_payroll.working_hour_per_day')"
          v-model="qty_hour_work"></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12" style="padding-top: 20px;">
        <v-btn class="btn-save-change" @click="saveChange">
          Save
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: {
    DatePicker
  },
  props: {
    payroll_id: "",
  },
  data() {
    return {
      start_date: "",
      end_date: "",
      newTitle: "",
      qty_day_work: 0,
      qty_hour_work: "",
      data: []
    }
  },
  methods: {
    fetchPayrollISingletem() {
      this.isLoading = true;
      this.$axios
        .get(`company/single/payroll/${this.payroll_id}`)
        .then((res) => {
          if (res.status === 200 && res.data.code === 200) {
            this.start_date = res.data.data.start_date;
            this.end_date = res.data.data.end_date;
            this.newTitle = this.extractText(res.data.data.name);
            this.qty_hour_work = res.data.data.hour_work_of_day;
            this.qty_day_work = res.data.data.day_work_of_month;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;

          if (error.response && error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            }
            else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
          // this.$emit("close")
        });
    },
    extractText(text) {
      const regex = /^(.*?)(?=\d{4}-\d{2}-\d{2} \/ \d{4}-\d{2}-\d{2}$)/;
      const match = text.match(regex);
      let text2 = match && match[1] ? match[1].trim() : text;

      if (text2.slice(-2) === '--') {
        text2 = text2.slice(0, -2);
      }
      else if (text2.slice(-1) === '-') {
        text2 = text2.slice(0, -1);
      }

      return text2;
    },
    saveChange() {
      const items = {
        startDate: this.start_date,
        endDate: this.end_date,
        newTitle: this.newTitle,
        day_work_of_month: this.qty_day_work,
        qty_hour_work: this.qty_hour_work
      }
      this.$axios.put(`company/edit/payroll/${this.payroll_id}`, items).then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
          this.$emit("close")
          this.$emit("success")
        }
      }).catch((error) => {
        this.isLoading = false;

          if (error.response && error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            }
            else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
      })
    },
    resetForm() {
      // Reset form fields here
      this.start_date = "";
      this.end_date = "";
      this.newTitle = "";
      this.qty_day_work = 0;
      this.qty_hour_work = "";
    },
  },
  watch: {
    '$store.state.modalEditState': {
      handler(newValue) {
        if (newValue) {
          this.fetchPayrollISingletem();
        } else {
          this.resetForm();
        }
      },
      immediate: true
    }
  }

}
</script>

<style scoped lang="scss">
.add-payrolls {
  width: 100%;
  height: 500px;
  overflow: hidden;

  .payrolls-title {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 1.6rem;
      margin-right: 5px;
    }

    h1 {
      font-family: $font-family;
      font-size: 1.5rem;
      font-weight: 500;
    }
  }
}
</style>