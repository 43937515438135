<template>
  <div class="add-payrolls">
    <div class="payrolls-title" style="padding: 30xp;">
      <!-- <h1><span><i class="far fa-calendar-alt"></i></span>{{ $t('payroll.create_payroll.title') }}</h1> -->
      <h1>{{ $t('payroll.create_payroll.title') }}</h1>
    </div>
    <v-row style="padding: 30px 30px 5px 30px;">
      <v-col cols="12" sm="12" md="12">
        <v-text-field outlined dense :label="$t('payroll.create_payroll.input_title')" v-model="newTitle"></v-text-field>
      </v-col>
    </v-row>
    <v-row style="padding: 10px 30px">
      <v-col cols="6" sm="6" md="6">
        <DatePicker class="date-time" style="width: 100%;" type="date" valueType="format" slot="activator" :placeholder="$t('payroll.create_payroll.start_date')"
          :append-to-body="true" v-model="start_date"></DatePicker>
      </v-col>
      <v-col cols="6" sm="6" md="6">
        <DatePicker class="date-time" style="width: 100%;" type="date" valueType="format" slot="activator" :placeholder="$t('payroll.create_payroll.end_date')"
          :append-to-body="true" v-model="end_date"></DatePicker>
      </v-col>
    </v-row>
    
    <v-row style="padding: 30px">
      <v-col cols="6" sm="6" md="6">
        <v-text-field outlined dense :label="$t('payroll.create_payroll.working_day_per_month')" v-model="qty_day_work"></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field outlined dense :label="$t('payroll.create_payroll.working_hour_per_day')"
          v-model="qty_hour_work"></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12" style="padding-top: 20px;">
        <v-btn class="btn-save-change" @click="saveChange">
          Save
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      start_date: "",
      end_date: "",
      newTitle: "",
      qty_day_work: 0,
      qty_hour_work: ""
    }
  },
  methods: {
    saveChange() {
      const items = {
        startDate: this.start_date,
        endDate: this.end_date,
        newTitle: this.newTitle,
        day_work_of_month: this.qty_day_work,
        qty_hour_work: this.qty_hour_work
      }
      this.$axios.post(`company/add/payroll`, items).then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
          this.$emit("close")
          this.$emit("success")
        }
      })
    },
    resetForm() {
      this.start_date = "";
      this.end_date = "";
      this.newTitle = "";
      this.qty_day_work = 0;
      this.qty_hour_work = "";
    },
  },
  watch: {
    '$store.state.modalEditState'(newValue) {
      if (!newValue) {
        this.resetForm();
      }
    }
  },
}
</script>

<style scoped lang="scss">
.add-payrolls {
  width: 100%;
  height: 500px;
  overflow: hidden;

  .payrolls-title {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 1.6rem;
      margin-right: 5px;
    }

    h1 {
      font-family: $font-family;
      font-size: 1.5rem;
      font-weight: 500;
    }
  }
}
</style>
